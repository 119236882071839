import { Notifier } from '@airbrake/browser';

const {
  AIRBRAKE_PROJECT_ID = '',
  AIRBRAKE_PROJECT_KEY = '',
  AIRBRAKE_ENVIRONMENT = '',
} = window.__AIRBRAKE_CONFIG__ || {};

let client: Notifier;

const getNotifier = (): typeof console.log | Notifier['notify'] => {
  if (import.meta.env.DEV || !AIRBRAKE_PROJECT_ID || !AIRBRAKE_PROJECT_KEY) {
    return console.log;
  }

  if (client) {
    return (err: unknown) => client.notify(err);
  }

  const environment = AIRBRAKE_ENVIRONMENT ? `${AIRBRAKE_ENVIRONMENT}-FE` : 'Frontend';

  client = new Notifier({
    projectId: Number(AIRBRAKE_PROJECT_ID),
    projectKey: AIRBRAKE_PROJECT_KEY,
    environment,
  });

  return (err: unknown) => client.notify(err);
};

export default getNotifier();
